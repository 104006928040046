import { useLazyQuery } from '@biletiniz/apollo';
import { COMBINE_FLIGHT_OFFERS_QUERY } from './gql';

export const useCombineFlightOffers = () => {
  const [combineFlightOffers, { data, loading, error }] = useLazyQuery(
    COMBINE_FLIGHT_OFFERS_QUERY
  );

  return {
    combineFlightOffers,
    combinedFlightOffers: data?.combineFlightOffers,
    combineFlightOffersError: error?.message,
    isLoadingCombineFlightOffers: loading,
  };
};
