import { useQuery } from '@biletiniz/apollo';
import { FLIGHT_PNR_DETAILS_QUERY } from './gql';

export const useFlightPNRDetails = flightPnrDetails => {
  const { data, error, loading } = useQuery(FLIGHT_PNR_DETAILS_QUERY, {
    variables: {
      ...flightPnrDetails,
    },
    fetchPolicy: 'no-cache',
    skip: !flightPnrDetails.pnr || !flightPnrDetails.firstPassengerSurname || !flightPnrDetails.flightProviderType,
  });

  return {
    flightPnrDetails: data?.flightPnrDetails,
    isLoading: loading,
    loadPnrDetailsError: error?.message,
  };
};
