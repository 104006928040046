import { useState } from '@biletiniz/ui-core';
import { useMutation } from '@biletiniz/apollo';
import { BOOK_BUS_OFFER_MUTATION, RESERVE_SEAT_MUTATION } from './gql';

const passengerInitialValues = (
  seatNumber,
  gender,
  firstName = '',
  lastName = '',
  tc = '',
  passportNo = '',
  passportCountry = {
    label: undefined,
    value: undefined,
  },
  tcdegil = false,
  hesCode = ''
) => ({
  firstName,
  lastName,
  tcdegil,
  tc,
  passportNo,
  passportCountry,
  hesCode,
  email: '',
  phone: '',
  seatNumber,
  gender,
});

export const useCheckoutBus = ({
  busOfferInformation,
  pnrDetails,
  busBookingInfo,
}) => {
  const [iframeUrl, setIframeUrl] = useState('');
  const [reservingData, setReservingData] = useState(null);

  const [
    bookingMutation,
    { loading: bookingLoading, error: bookingError },
  ] = useMutation(BOOK_BUS_OFFER_MUTATION);

  const [
    reservingMutation,
    { loading: reservingLoading, error: reservingError },
  ] = useMutation(RESERVE_SEAT_MUTATION);

  const reserving = reservingInfo =>
    reservingMutation({
      variables: {
        session: busOfferInformation.session,
        busOfferId: busOfferInformation.id,
        companyNumber: parseInt(busOfferInformation.companyNumber),
        origin: parseInt(busOfferInformation.originCode),
        destination: parseInt(busOfferInformation.destinationCode),
        departureAt: busOfferInformation.departureDate,
        hour: busOfferInformation.hour,
        lineNumber: parseInt(busOfferInformation.lineNumber),
        trackingNumber: busOfferInformation.trackingNumber,
        passengers: reservingInfo?.passengers?.map(passenger => ({
          seatNumber: parseInt(passenger.seatNumber),
          givenName: passenger.firstName,
          surname: passenger.lastName,
          hesCode: passenger.hesCode.replace(/-/g, ''),
          tc: passenger.tc,
          passportNo: passenger.passportNo,
          passportCountry: passenger.passportCountry,
          tcdegil: passenger.tcdegil,
        })),
        telefonNo: reservingInfo.gsmNumber,
        gender: busOfferInformation.gender,
        numberOfPassengers: busOfferInformation.seats.length,
        buyerEmail: reservingInfo?.email,
      },
    }).then(({ data }) =>
      setReservingData({
        ...data.seatReserving,
        firstPassengerSurname: reservingInfo.passengers[0].lastName,
      })
    );

  const booking = bookingInfo =>
    bookingMutation({
      variables: {
        busOfferId: busOfferInformation.id,
        session: busOfferInformation.session,
        companyNumber: parseInt(busOfferInformation.companyNumber),
        origin: parseInt(busOfferInformation.originCode),
        destination: parseInt(busOfferInformation.destinationCode),
        departureAt: busOfferInformation.departureDate,
        hour: busOfferInformation.hour,
        lineNumber: parseInt(busOfferInformation.lineNumber),
        trackingNumber: busOfferInformation.trackingNumber,
        passengers: bookingInfo?.passengers?.map(passenger => ({
          seatNumber: parseInt(passenger.seatNumber),
          givenName: passenger.firstName,
          surname: passenger.lastName,
          hesCode: passenger.hesCode.replace(/-/g, ''),
          tc: passenger.tc,
          passportNo: passenger.passportNo,
          passportCountry: passenger.passportCountry,
          tcdegil: passenger.tcdegil,
        })),
        paymentCard: {
          cardNumber: bookingInfo.cardNumber,
          expireYear: bookingInfo.expire.year,
          expireMonth: bookingInfo.expire.month,
          cvc: bookingInfo.cvc,
          cardHolderName: bookingInfo.cardHolderName,
        },
        telefonNo: bookingInfo.gsmNumber,
        gender: busOfferInformation.gender,
        numberOfPassengers: busOfferInformation.seats.length,
        buyerEmail: bookingInfo?.email,
        pnr: busOfferInformation?.pnr,
        prepaymentActive: busOfferInformation.prepaymentActive,
        utmSource: busOfferInformation?.utmSource,
        marker: busOfferInformation?.marker,
      },
    }).then(({ data }) => {
      const apiRootUrl = process.env.NEXT_PUBLIC_API_ROOT_URL || '';
      setIframeUrl(
        `${apiRootUrl}/bus-payment-checkout/${data.busOfferBooking}`
      );
    });

  const emptyInitialValues = {
    passengers: busOfferInformation?.seats?.map(seatNumber =>
      passengerInitialValues(seatNumber, busOfferInformation.gender)
    ),
    email: '',
    gsmNumber: '',
    cardHolderName: '',
    cardNumber: '',
    expire: {
      month: '',
      year: '',
    },
    cvc: '',
    termsOfService: false,
  };

  const sessionFullInitialValues = {
    passengers: busBookingInfo?.passengers?.reduce(
      (result, passengerDetails) => {
        if (busOfferInformation?.seats.includes(passengerDetails.seatNo)) {
          result.push(
            passengerInitialValues(
              passengerDetails.seatNo,
              passengerDetails.gender,
              passengerDetails.name,
              passengerDetails.surname,
              passengerDetails.identificationNumber,
              passengerDetails.passportNo,
              {
                label: passengerDetails.passportCountry.label,
                value: passengerDetails.passportCountry.value,
              },
              passengerDetails.tcdegil,
              `${passengerDetails.hesCode.substr(
                0,
                4
              )}-${passengerDetails.hesCode.substr(
                4,
                4
              )}-${passengerDetails.hesCode.substr(8)}`
            )
          );
        }
        return result;
      },
      []
    ),
    email: busBookingInfo?.email,
    gsmNumber: `0 (${busBookingInfo?.tel.substr(
      0,
      3
    )}) ${busBookingInfo?.tel.substr(3, 3)} ${busBookingInfo?.tel.substr(
      6,
      2
    )} ${busBookingInfo?.tel.substr(8)}`,
    cardHolderName: busBookingInfo?.cardHolderName,
    cardNumber: `${busBookingInfo?.cardNumber.substr(
      0,
      4
    )}-${busBookingInfo?.cardNumber.substr(
      4,
      4
    )}-${busBookingInfo?.cardNumber.substr(
      8,
      4
    )}-${busBookingInfo?.cardNumber.substr(12)}`,
    expire: busBookingInfo?.expire,
    cvc: busBookingInfo?.cvc,
    termsOfService: false,
  };

  const pnrFullInitialValues = {
    passengers: pnrDetails?.passengersDetails?.reduce(
      (result, passengerDetails) => {
        if (busOfferInformation?.seats.includes(passengerDetails.seatNo)) {
          result.push(
            passengerInitialValues(
              passengerDetails.seatNo,
              passengerDetails.gender,
              passengerDetails.name,
              passengerDetails.surname,
              passengerDetails.identificationNumber,
              passengerDetails.passportNo,
              {
                label: passengerDetails.passportCountry.label,
                value: passengerDetails.passportCountry.value,
              },
              passengerDetails.tcdegil,
              `${passengerDetails.hesCode.substr(
                0,
                4
              )}-${passengerDetails.hesCode.substr(
                4,
                4
              )}-${passengerDetails.hesCode.substr(8)}`
            )
          );
        }
        return result;
      },
      []
    ),
    email: pnrDetails?.pnrInformations.email,
    gsmNumber: `0 (${pnrDetails?.pnrInformations.tel.substr(
      0,
      3
    )}) ${pnrDetails?.pnrInformations.tel.substr(
      3,
      3
    )} ${pnrDetails?.pnrInformations.tel.substr(
      6,
      2
    )} ${pnrDetails?.pnrInformations.tel.substr(8)}`,
    cardHolderName: '',
    cardNumber: '',
    expire: {
      month: '',
      year: '',
    },
    cvc: '',
    termsOfService: false,
  };

  let formInitialValues = emptyInitialValues;
  if (pnrDetails) {
    formInitialValues = pnrFullInitialValues;
  } else if (busBookingInfo) {
    formInitialValues = sessionFullInitialValues;
  }

  return {
    bookingBusOfferError: bookingError?.message,
    reservingBusOfferError: reservingError?.message,
    booking,
    reserving,
    bookingLoading,
    reservingLoading,
    reservingData,
    iframeUrl,
    setIframeUrl,
    formInitialValues,
  };
};
