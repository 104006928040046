import { gql } from '@biletiniz/apollo';

export const ALL_AIRPORTS_QUERY = gql`
  query flightTypeaheadSearch($keyword: String, $skip: Int) {
    flightTypeaheadSearch(keyword: $keyword, skip: $skip) {
      labelTr
      labelEn
      value
      cityCode
      isCity
      level
    }
  }
`;
