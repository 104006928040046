import { useMutation } from '@biletiniz/apollo';
import { useState } from '@biletiniz/ui-core';
import { CANCEL_BUS_TICKET_SELL_MUTATION } from './gql';

export const useCancelBusTicketSell = (router, i18n) => {
  const [cancelBusTicketSellMutation, { loading, error }] = useMutation(
    CANCEL_BUS_TICKET_SELL_MUTATION
  );
  const [cancelationStatus, setCancelationStatus] = useState(false);
  const cancelBusTicketSell = ticketInformation =>
    cancelBusTicketSellMutation({
      variables: {
        PNRNumber: ticketInformation.PNRNumber,
        seatNo: parseInt(ticketInformation.seatNo),
        amount: parseInt(ticketInformation.amount),
        lastName: ticketInformation.lastName,
        tel: ticketInformation.tel,
        buyerEmail: ticketInformation.buyerEmail,
        lastTicket:
          parseInt(ticketInformation.seatNo) === 0 ||
          ticketInformation.soldTicketsNumber <= 1,
      },
    }).then(({ data }) => {
      if (data.cancelBusTicketSell) {
        setCancelationStatus(data.cancelBusTicketSell);
        if (data.cancelBusTicketSell === true) router.reload();
        else throw new Error(i18n.t('SomethingWrongContactSupport'));
      }
    });

  return {
    cancelBusTicketSellError: error?.message,
    cancelBusTicketSellLoading: loading,
    cancelationStatus,
    cancelBusTicketSell,
  };
};
