import { useQuery } from '@biletiniz/apollo';
import { DEPARTURE_POINTS_QUERY } from './gql';

export const useDeparturePoints = busOfferInfo => {
  const { data, loading } = useQuery(DEPARTURE_POINTS_QUERY, {
    variables: {
      ...busOfferInfo,
    },
    fetchPolicy: 'no-cache',
    skip:
      !busOfferInfo.companyNumber ||
      !busOfferInfo.lineNumber ||
      !busOfferInfo.origin ||
      !busOfferInfo.destination ||
      !busOfferInfo.trackingNumber ||
      !busOfferInfo.departureDate,
  });
  return {
    departurePoints: data?.departurePoints,
    isLoading: loading,
  };
};
