import { useQuery } from '@biletiniz/apollo';

import { ALL_AIRPORTS_QUERY } from './gql';

export const useFlightTypeahead = () => {
  const airportsQuery = useQuery(ALL_AIRPORTS_QUERY, {
    variables: {
      keyword: '',
      skip: 0,
    },
    skip: true,
  });

  const getAirports = ({ keyword, skip, lang }) => {
    return airportsQuery.refetch({ keyword, skip }).then(({ data }) =>
      data.flightTypeaheadSearch.map(item => ({
        ...item,
        label: lang.toLowerCase() === 'tr' ? item.labelTr : item.labelEn,
      }))
    );
  };

  return {
    getAirports,
  };
};
