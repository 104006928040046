import { useMutation } from '@biletiniz/apollo';
import { useState } from '@biletiniz/ui-core';
import { CANCEL_BUS_RESERVATION_MUTATION } from './gql';

export const useCancelBusReservation = router => {
  const [cancelBusReservationMutation, { loading, error }] = useMutation(
    CANCEL_BUS_RESERVATION_MUTATION
  );
  const [cancelationStatus, setCancelationStatus] = useState(false);
  const cancelBusReservation = reservingInfo =>
    cancelBusReservationMutation({
      variables: {
        PNRNumber: reservingInfo.PNRNumber,
        lastName: reservingInfo.lastName,
        tel: reservingInfo.tel,
        buyerEmail: reservingInfo.buyerEmail,
        seatNo: parseInt(reservingInfo.seatNo),
      },
    }).then(({ data }) => {
      setCancelationStatus(data.cancelBusReservation);
      if (data.cancelBusReservation === 'true') {
        router.reload();
      }
    });

  return {
    cancelBusReservationError: error?.message,
    cancelBusReservationLoading: loading,
    cancelBusReservation,
    cancelationStatus,
  };
};
