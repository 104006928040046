import { useEffect, useState } from '@biletiniz/ui-core';
import { useLazyQuery } from '@biletiniz/apollo';
import { BUS_EXTRA_INFORMATION_QUERY } from './gql';

const getBusExtraInformationVariable = busOffer => {
  return {
    origin: parseInt(busOffer?.departure.value),
    destination: parseInt(busOffer?.arrival.value),
    departureAt: busOffer?.departure.at,
    hour: busOffer?.departure.hour,
    lineNumber: parseInt(busOffer?.expeditionDetails.lineNumber),
    trackingNumber: busOffer?.expeditionDetails.trackingNumber,
    companyNumber: parseInt(busOffer?.company.number),
    operationType: 0,
  };
};

export const useBusExtraInformation = busOffer => {
  const [loaded, setLoaded] = useState(false);
  const [loadBusExtraInformation, { data, error, loading }] = useLazyQuery(
    BUS_EXTRA_INFORMATION_QUERY,
    {
      fetchPolicy: `network-only`,
    }
  );
  useEffect(() => {
    const busExtraInformationVariables = getBusExtraInformationVariable(
      busOffer
    );
    if (
      busExtraInformationVariables?.departureAt &&
      busExtraInformationVariables?.origin &&
      busExtraInformationVariables?.destination
    ) {
      loadBusExtraInformation({
        variables: {
          ...busExtraInformationVariables,
        },
      });
    }
  }, [busOffer]);
  useEffect(() => {
    if (data && !loaded) {
      setLoaded(true);
    }
  }, [data]);
  return {
    ...data?.busExtraInformation,
    loadBusExtraInformationError: error?.message,
    isLoading: loading,
  };
};
