import { useQuery } from '@biletiniz/apollo';
import { PNR_DETAILS_QUERY } from './gql';

export const usePNRDetails = pnrDetails => {
  const { data, error, loading } = useQuery(PNR_DETAILS_QUERY, {
    variables: {
      ...pnrDetails,
    },
    fetchPolicy: 'no-cache',
    skip: !pnrDetails.PNRNumber || !pnrDetails.lastNameOrTelephoneNumber,
  });

  return {
    pnrDetails: data?.pnrDetails,
    isLoading: loading,
    loadPnrDetailsError: error?.message,
  };
};
