import { useQuery } from '@biletiniz/apollo';
import { BUS_OFFERS_QUERY } from './gql';

export const useBusOffer = ({ session, busOfferId }) => {
  const busOfferQuery = useQuery(BUS_OFFERS_QUERY, {
    variables: {
      session,
      busOfferId,
    },
    skip: !session || !busOfferId,
    fetchPolicy: 'no-cache',
  });
  return {
    busOffer: busOfferQuery?.data?.busOffer,
    loadBusOfferError: busOfferQuery?.error?.message,
    isLoadingBusOffer: busOfferQuery.loading,
  };
};
